import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, IconButton, TextField } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/Constants';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EditArticleV3() {
    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const articleId = queryParams.get('id');
    const [articleData, setArticleData] = useState({ name: '' });
    const [articleContent, setArticleContent] = useState([]);

    const handleArticleContent = (content) => {
        setArticleContent(content.map(item => ({ ...item, editable: false })));
    };

    const handleDeleteTask = (index) => {
        const updatedContent = [...articleContent];
        updatedContent.splice(index, 1);
        setArticleContent(updatedContent);
    };

    const handleContentChange = (index, newContent) => {
        const updatedContent = [...articleContent];
        updatedContent[index].content = newContent;
        setArticleContent(updatedContent);
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/v2/article-content/${articleId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ content: articleContent })
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Article updated successfully:', data);
                navigate(-1)
            } else {
                console.error('Error updating article:', data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const fetchArticleData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/admin/v2/article/${articleId}`);
                if (response.ok) {
                    const data = await response.json();
                    setArticleData(data.data.articleData);
                    handleArticleContent(data.data.articleData.content);
                } else {
                    console.error('Failed to fetch article data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchArticleData();
    }, [articleId]);

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Edit Article {articleData.name && <>- {articleData.name}</>}
                    </Typography>
                    {articleContent.map((content, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, p: 1, borderRadius: '4px' }}>
                            <TextField
                                value={content.content}
                                onChange={(e) => handleContentChange(index, e.target.value)}
                                fullWidth
                            />
                            <IconButton onClick={() => handleDeleteTask(index)} color="info">
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <Box mt={2}>
                        <Button variant='contained' color='primary' onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
