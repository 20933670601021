import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../Api/Constants';
import DrawerNav from '../Navigation/DrawerNav';
import { showTimeInAgoFormat } from '../../Utils/Time';
import { useNavigate } from 'react-router-dom';

function Users() {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/users/leaderboard`);
        if (response.data.data.usersList) {
          setUsers(response.data.data.usersList);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleDelete = async () => {
    if (selectedUser) {
      try {
        await axios.delete(`${BASE_URL}/api/v1/user/${selectedUser.uid}`);
        setUsers(users.filter(user => user.uid !== selectedUser.uid));
        handleClose();
      } catch (error) {
        console.error('Error deleting user:', error);
        handleClose();
      }
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <DrawerNav />
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <div>
          <h1 className='text-2xl font-bold py-4'>All Users</h1>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '5%' }}>S. No</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>User Data</TableCell>
                  <TableCell>Delete User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => (
                  <TableRow key={user._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.username || ''}</TableCell>
                    <TableCell>{showTimeInAgoFormat(user.createdAt || 1712131292912)}</TableCell>
                    <TableCell onClick={() => navigate(`/users/${user.uid}`)}>
                      <span className='text-blue-500 cursor-pointer underline'>View</span>
                    </TableCell>
                    <TableCell>
                      <Button color="error" onClick={() => handleDeleteClick(user)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Delete User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this user? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default Users;
