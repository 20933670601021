import React, { useState, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../Api/Constants';
import DrawerNav from '../Navigation/DrawerNav';
import { showTimeInAgoFormat } from '../../Utils/Time';
import { useNavigate } from 'react-router-dom';

function SearchUsers() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        const fetchUsers = async () => {
          try {
            const response = await axios.get(`${BASE_URL}/api/v1/users/search`, {
              params: {
                query: search
              }
            });
            if (response.data.data) {
              setUsers(response.data.data);
            }
          } catch (error) {
            console.error('Error fetching users:', error);
          }
        };

        fetchUsers();
      } else {
        setUsers([]); // Clear the list when the search box is empty
      }
    }, 2000); // 2 seconds delay

    return () => clearTimeout(delayDebounceFn); // Cleanup timeout if component unmounts or search changes
  }, [search]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <DrawerNav />
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <div>
          <TextField
            label="Search by Username or Email"
            variant="outlined"
            value={search}
            onChange={handleSearch}
            fullWidth
            margin="normal"
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '5%' }}>S. No</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>User Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length > 0 ? (
                  users.map((user, index) => (
                    <TableRow key={user._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.username || ''}</TableCell>
                      <TableCell>{showTimeInAgoFormat(user.createdAt || 1712131292912)}</TableCell>
                      <TableCell onClick={() => navigate(`/users/${user.uid}`)}>
                        <span className='text-blue-500 cursor-pointer underline'>View</span>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No users found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Box>
  );
}

export default SearchUsers;
