import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import DrawerNav from '../Navigation/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import axios from 'axios'

export default function EditRecommendationV2() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const articleId = queryParams.get('id')
    const [articleData, setArticleData] = useState({ name: '' })
    const [type, setType] = useState('')
    const [content, setContent] = useState('')
    const navigate = useNavigate()

    const handleAddContent = async () => {
        const newContent = {
            type,
            content
        }
        
        try {
            const response = await axios.put(`${BASE_URL}/api/admin/v1/recommendation-content/${articleId}`, newContent)
            if (response.status === 200) {
                setType('')
                setContent('')
                navigate(-1)
            } else {
                console.error('Failed to add content')
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add Content {articleData.name && <>- {articleData.name}</>}
                    </Typography>
                    <Autocomplete
                        options={['para', 'image', 'heading', 'title', 'bullets_list', 'numbers_list']}
                        value={type}
                        onChange={(event, newValue) => setType(newValue)}
                        renderInput={(params) => <TextField {...params} label="Type" margin="normal" fullWidth />}
                    />

                    <TextField
                        label="Content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" sx={{ mt: 2}} onClick={handleAddContent}>
                        Add Content
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
