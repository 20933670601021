import React, { useState } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Grid, Typography, Button, IconButton } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import axios from 'axios';
import { BASE_URL } from '../../Api/Constants';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import 'react-toastify/dist/ReactToastify.css';
import { PROFILING_QUESTIONS } from '../../Data/Profiling';

const CreateRecommendation = () => {
    const questions = PROFILING_QUESTIONS

    const [questionAnswers, setQuestionAnswers] = useState([{ questionId: questions[0].id, answer: questions[0].options[0] }]);
    const [recommendation, setRecommendation] = useState({
        title: "",
        description: "",
        link: ""
    });

    const handleQuestionChange = (index, e) => {
        const questionId = e.target.value;
        const newQuestionAnswers = [...questionAnswers];
        newQuestionAnswers[index].questionId = questionId;

        const selectedQuestionObj = questions.find(q => q.id === questionId);
        newQuestionAnswers[index].answer = selectedQuestionObj.options[0]; // Reset to first option when question changes

        setQuestionAnswers(newQuestionAnswers);
    };

    const handleAnswerChange = (index, e) => {
        const newQuestionAnswers = [...questionAnswers];
        newQuestionAnswers[index].answer = e.target.value;
        setQuestionAnswers(newQuestionAnswers);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRecommendation(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddQuestion = () => {
        setQuestionAnswers([...questionAnswers, { questionId: questions[0].id, answer: questions[0].options[0] }]);
    };

    const handleRemoveQuestion = (index) => {
        const newQuestionAnswers = questionAnswers.filter((_, i) => i !== index);
        setQuestionAnswers(newQuestionAnswers);
    };

    const handleSaveRecommendation = async () => {
        const recommendationData = {
            questions: questionAnswers,
            title: recommendation.title,
            description: recommendation.description,
            link: recommendation.link,
        };

        console.log('Recommendation Data : ', recommendationData)

        try {
            const response = await axios.post(`${BASE_URL}/api/admin/v1/manage-recommendations`, recommendationData);
            console.log('Recommendation saved successfully:', response.data);
            toast.success('Recommendation saved successfully!');

            // Clear the input fields
            setRecommendation({ title: "", description: "", link: "" });
            setQuestionAnswers([{ questionId: questions[0].id, answer: questions[0].options[0] }]);
        } catch (error) {
            console.error('Error saving recommendation:', error);
            toast.error('Failed to save recommendation.');
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
                <Typography variant="h4" gutterBottom>
                    Create Recommendations
                </Typography>
                <Grid container spacing={3}>
                    {questionAnswers.map((qa, index) => {
                        const selectedQuestionObj = questions.find(q => q.id === qa.questionId);
                        return (
                            <Grid container item spacing={2} key={index}>
                                <Grid item xs={12} sm={5}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel shrink>Question</InputLabel>
                                        <Select
                                            value={qa.questionId}
                                            onChange={(e) => handleQuestionChange(index, e)}
                                            label="Question"
                                        >
                                            {questions.map((question) => (
                                                <MenuItem key={question.id} value={question.id}>
                                                    {question.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel shrink>Answer</InputLabel>
                                        <Select
                                            value={qa.answer}
                                            onChange={(e) => handleAnswerChange(index, e)}
                                            label="Answer"
                                        >
                                            {selectedQuestionObj && selectedQuestionObj.options.map((option, i) => (
                                                <MenuItem key={i} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton onClick={() => handleAddQuestion()}>
                                        <AddIcon />
                                    </IconButton>
                                    {questionAnswers.length > 1 && (
                                        <IconButton onClick={() => handleRemoveQuestion(index)}>
                                            <RemoveIcon />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Recommendation Title"
                            name="title"
                            value={recommendation.title}
                            onChange={handleInputChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Recommendation Description"
                            name="description"
                            value={recommendation.description}
                            onChange={handleInputChange}
                            variant="outlined"
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Recommendation Link"
                            name="link"
                            value={recommendation.link}
                            onChange={handleInputChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleSaveRecommendation}
                        >
                            Save Recommendation
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CreateRecommendation;
