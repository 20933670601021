import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Box, Typography, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/Constants';

function User() {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/v1/users/profile?uid=${id}`);
                if (response.status === 200) {
                    console.log('response.data.data.user: ', response.data.data.user)
                    setUser(response.data.data.user);
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUser();
    }, [id]);

    if (!user) return <div>Loading...</div>;

    const getValue = (value) => value || 'N/A';

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <IconButton onClick={() => navigate('/users')}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h5" component="div" sx={{ ml: 2 }}>
                        User Data
                    </Typography>
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>{getValue(user.email)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Full Name</TableCell>
                                <TableCell>{getValue(user.fullname)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Gender</TableCell>
                                <TableCell>{getValue(user.gender)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Height</TableCell>
                                <TableCell>{getValue(user.height)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Weight</TableCell>
                                <TableCell>{getValue(user.weight)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Occupation</TableCell>
                                <TableCell>{getValue(user.occupation)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>{getValue(user.phoneNumber)}</TableCell>
                            </TableRow>
                            {user.profiling && Object.keys(user.profiling).map((key) => (
                                <TableRow key={key}>
                                    <TableCell>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</TableCell>
                                    <TableCell>{getValue(user.profiling[key])}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}

export default User;
