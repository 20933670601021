import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/Constants';
import axios from 'axios';

export default function EditArticleV2() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const articleId = queryParams.get('id');
    const [articleData, setArticleData] = useState({ name: '' });
    const [type, setType] = useState('');
    const [content, setContent] = useState('');
    const [contentArray, setContentArray] = useState(['']);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the article data if needed
    }, [articleId]);

    const handleAddContent = async () => {
        const requestBody = {
            type,
            content: type === 'bullets_list' || type === 'numbers_list' ? contentArray : content,
        };

        try {
            const response = await axios.put(`${BASE_URL}/api/admin/v1/article-content/${articleId}`, requestBody);
            if (response.status === 200) {
                setType('');
                setContent('');
                setContentArray(['']);
                navigate(-1);
            } else {
                console.error('Failed to add content');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleAddItemToList = () => {
        setContentArray([...contentArray, '']);
    };

    const handleListItemChange = (index, event) => {
        const newArray = [...contentArray];
        newArray[index] = event.target.value;
        setContentArray(newArray);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add Content {articleData.name && <>- {articleData.name}</>}
                    </Typography>
                    <Autocomplete
                        options={['para', 'image', 'heading', 'title', 'bullets_list', 'numbers_list']}
                        value={type}
                        onChange={(event, newValue) => {
                            setType(newValue);
                            if (newValue === 'bullets_list' || newValue === 'numbers_list') {
                                setContentArray(['']);
                            } else {
                                setContent('');
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Type" margin="normal" fullWidth />}
                    />

                    {type === 'bullets_list' || type === 'numbers_list' ? (
                        <Box>
                            {contentArray.map((item, index) => (
                                <TextField
                                    key={index}
                                    label={`Item ${index + 1}`}
                                    value={item}
                                    onChange={(event) => handleListItemChange(index, event)}
                                    fullWidth
                                    margin="normal"
                                />
                            ))}
                            <Button variant="outlined" onClick={handleAddItemToList} sx={{ mt: 2 }}>
                                Add Item
                            </Button>
                        </Box>
                    ) : (
                        <TextField
                            label="Content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    )}
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleAddContent}>
                        Add Content
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
