import * as React from 'react'
import { useEffect } from 'react'
import { Autocomplete, Button, Grid, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import ReactGA from 'react-ga'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../Navigation/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AddArticle = () => {
    const [articleName, setArticleName] = useState('')
    const [articleBackground, setArticleBackground] = useState('https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/754949_484286.jpg')
    const [articleDescription, setArticleDescription] = useState('')
    const [platformType, setPlatformType] = useState('All')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetInputFields = () => {
        setArticleName('')
        setArticleDescription('')
        setArticleBackground('')
        setPlatformType('All')
    }

    const addNewContest = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: articleName,
                description: articleDescription,
                backgroundImage: articleBackground,
                platform: platformType.toLowerCase()
            })
        }

        fetch(`${BASE_URL}/api/admin/v1/article`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetInputFields()
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={4}>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <h1 className='text-4xl font-bold text-gray-500 my-5'> Add New Article</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} sx={{ mt: 4 }} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Article Name'
                                id='combo-box-demo'
                                value={articleName}
                                sx={{ width: '50%' }}
                                onChange={(event) => {
                                    setArticleName(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} sx={{ mt: 4 }} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Article Description'
                                multiline
                                id='combo-box-demo'
                                value={articleDescription}
                                sx={{ width: '50%', }}
                                onChange={(event) => {
                                    setArticleDescription(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} sx={{ mt: 4 }} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Article Background Image'
                                id='combo-box-demo'
                                value={articleBackground}
                                sx={{ width: '50%' }}
                                onChange={(event) => {
                                    setArticleBackground(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} sx={{ mt: 4 }} className='pt-5'>
                            <Autocomplete
                                disablePortal
                                id='platform-type-dropdown'
                                options={['All', 'Website', 'Mobile']}
                                value={platformType}
                                onChange={(event, newValue) => {
                                    setPlatformType(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} label='Platform Type' sx={{ width: '50%' }} />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} sx={{ mt: 4 }} className='pt-5 pb-12'>
                            <Button className='h-12' variant='contained' sx={{ width: '50%' }} color='info' onClick={addNewContest}>
                                Add Article
                            </Button>
                        </Grid>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default AddArticle
