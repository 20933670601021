import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import Home from './Components/Home/Home'
import Header from './Components/Navigation/Header'
import Login from './Components/Login/Login'
import isAuthenticated from './Auth/AuthenticateUser'
import EditUser from './Components/Users/EditUser'
import ViewArticles from './Components/Articles/ViewArticles'
import EditArticle from './Components/Articles/EditArticle'
import AddArticle from './Components/Articles/AddArticle'
import EditArticleV2 from './Components/Articles/EditArticleV2'
import EditArticleV3 from './Components/Articles/EditArticleV3'
import AddRecommendation from './Components/Recommendations/AddRecommendation'
import ViewRecommendations from './Components/Recommendations/ViewRecommendations'
import EditRecommendation from './Components/Recommendations/EditRecommendation'
import EditRecommendationV2 from './Components/Recommendations/EditRecommendationV2'
import EditRecommendationV3 from './Components/Recommendations/EditRecommendationV3'
import Users from './Components/Users/Users'
import User from './Components/Users/User'
import SearchUsers from './Components/Users/SearchUsers'
import ViewRecommendationTable from './Components/Recommendations/ViewRecommendationManagement'
import CreateRecommendation from './Components/Recommendations/CreateRecommendation'
import ImageUpload from './Components/Home/ImageUpload'

function App() {
    const PrivateWrapper = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
    }
    return (
        <BrowserRouter>
            <div>
                <Header />
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/' element={<Home />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-article' element={<AddArticle />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/upload-media' element={<ImageUpload />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-recommendation' element={<AddRecommendation />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/users' element={<Users />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/search-users' element={<SearchUsers />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/users/:id' element={<User />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/create-recommendation' element={<CreateRecommendation />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/view-recommendation-management' element={<ViewRecommendationTable />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/articles' element={<ViewArticles />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/recommendations' element={<ViewRecommendations />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/article' element={<EditArticle />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/article-v2' element={<EditArticleV2 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/article-v3' element={<EditArticleV3 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/recommendation' element={<EditRecommendation />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/recommendation-v2' element={<EditRecommendationV2 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/recommendation-v3' element={<EditRecommendationV3 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-user' element={<EditUser />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App
